<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="updateReport">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red text-uppercase">
              ACCIDENT INCIDENT REPORT
            </h5>
          </div>
          <div class="col-auto">
            <template v-if="isAllowed('accident-incident.view.edit') && isOwnVessel()">

              <button class="e-btn e-btn-outlined-red ml-2" type="button" v-if="toEdit" @click="cancelEditReport">Cancel</button>
              <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editValues">Edit Report</button>
              <button class="e-btn e-btn-blue ml-2" type="submit" v-if="toEdit">Save</button>
            </template>

            <template v-if="isAllowed('accident-incident.view.download.air') && isOwnVessel()">
              <div class="float-right ml-2 dropdown dropright" v-if="!toEdit">
                <button id="actionAIRDropdown" aria-expanded="false" aria-haspopup="true" class="mr-2 e-btn e-btn-blue-gray force-white-all"
                        data-toggle="dropdown">
                  <font-awesome-icon class="mr-1" icon="download"/>
                  Download Accident Incident Report
                </button>
                <div aria-labelledby="actionAIRDropdown" class="dropdown-menu">
                  <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadAIReport('xlsx')">
                    <font-awesome-icon icon="file-excel"/>
                    Excel
                  </button>
                  <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="downloadAIReport('pdf')">
                    <font-awesome-icon icon="file-pdf"/>
                    PDF
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
        <table class="w-100">
          <tr>
            <td style="width: 12%;">Report Number</td>
            <td>{{ accidentIncidentReport.code }}</td>
            <td>
              Status
              <font-awesome-icon icon="question-circle"
                                 data-toggle="modal"
                                 style="cursor:pointer"
                                 data-target="#requirementsBeforeClosing">
              </font-awesome-icon>
            </td>
            <td v-if="editStatus">
              <div class="row align-content-center align-items-center">
                <div class="col-8">
                  <select class="form-control form-control-sm" v-model="reportStatusId">
                    <template v-for="reportStatusKey in reportStatusKeys">
                      <option v-if="reportStatusKey.visible" :value="reportStatusKey.id" :disabled="reportStatusKey.disabled">
                        {{ reportStatusKey.name }}
                      </option>
                    </template>
                  </select>
                </div>
                <div class="col-auto">
                  <button type="button" class="btn btn-success btn-xs mr-2" :disabled="currentStatus===reportStatusId"
                          @click="saveReportStatus">
                    <font-awesome-icon icon="save" class="force-white-all">

                    </font-awesome-icon>
                    Save
                  </button>
                  <button type="button" class="btn btn-danger btn-xs" @click="editStatus=false;">
                    <font-awesome-icon icon="ban" class="force-white-all"></font-awesome-icon>
                    Cancel
                  </button>
                </div>
              </div>
            </td>
            <td v-else>
              <button type="button" class="'e-btn e-btn-xs px-5" :class="['bg-'+currentReportStatus.color]"
                      @click="editReportStatus">{{ currentReportStatus.name ? currentReportStatus.name : 'NEW' }}
              </button>
            </td>
          </tr>
          <tr>
            <td>Vessel <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase">
              {{ accidentIncidentReport.vessel ? accidentIncidentReport.vessel : '' }} <span
              class="font-weight-bolder">{{ accidentIncidentReport.vesselFlag ? '(' + accidentIncidentReport.vesselFlag + ')' : '' }}</span>
            </td>
            <td style="width: 11%;">Event Class
              <sub class="lead e-text-red font-weight-bold mr-2">*</sub>
              <font-awesome-icon icon="question-circle"
                                 data-toggle="modal"
                                 style="cursor:pointer"
                                 data-target="#eventClassDescription">
              </font-awesome-icon>
            </td>
            <td v-if="toEdit" style="width: 38%">
              <select class="form-control form-control-sm" v-model="editReport.event_class_id">
                <option :value="null">Select Event Class</option>
                <option :value="eventImpact.id" class="" v-for="eventImpact in eventImpactKeys" :key="eventImpact.id">
                  {{ eventImpact.name }}
                </option>
              </select>
            </td>
            <td v-else style="width: 38%" class="text-uppercase">
              {{ accidentIncidentReport.eventClass }}
            </td>
          </tr>
          <tr>
            <td>Report Title <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-capitalize pr-5">
              <input type="text" class="form-control form-control-sm" v-model="editReport.title" v-if="toEdit">
              <span v-else>{{ accidentIncidentReport.title }}</span>
            </td>

            <template v-if="isAllowed('accident-incident.confidential_report')">
              <td>Confidential Report</td>
              <td class="text-capitalize">
                <select class="form-control form-control-sm text-uppercase" id="EventClass" v-model="editReport.is_confidential_report"
                        v-if="toEdit">
                  <option :value="true">YES</option>
                  <option :value="false">NO</option>
                </select>
                <span v-else class="font-weight-bold">{{ accidentIncidentReport.is_confidential_report ? 'YES' : 'NO' }}</span>
              </td>
            </template>
          </tr>
          <tr>
            <td></td>
            <td class="text-capitalize pr-5">

            </td>

            <template v-if="isAllowed('accident-incident.confidential_report')">
              <td>Last Updated By:</td>
              <td class="text-capitalize">
                {{ accidentIncidentReport.last_updated_by.first_name }} {{ accidentIncidentReport.last_updated_by.last_name }}
              </td>
            </template>
          </tr>
        </table>
      </form>
      <hr>
      <p class="mb-2 pb-0 e-text-red" style="font-size: 15px;">MONTHLY SAFETY PROTECTION COMMITTEE STATUS</p>
      <table class="w-100">
        <tr>
          <td class="align-middle">KOBE</td>
          <td class="align-middle pr-5">
            <select class="form-control form-control-sm" v-model="editReport.kobe_status_id" v-if="toEdit">
              <option :value="null">---- SELECT STATUS ----</option>
              <option :value="3">Discussed</option>
              <option :value="2">Reviewing</option>
              <option :value="1">Pending</option>
            </select>
            <span v-else class="font-weight-bold">{{ accidentIncidentReport.kobe_status | toUpper }}</span>
          </td>
          <td class="align-middle">ROHQ</td>
          <td class="align-middle pr-5">
            <select class="form-control form-control-sm" v-model="editReport.rohq_status_id" v-if="toEdit">
              <option :value="null">---- SELECT STATUS ----</option>
              <option :value="3">Discussed</option>
              <option :value="2">Reviewing</option>
              <option :value="1">Pending</option>
            </select>
            <span v-else class="font-weight-bold">{{ accidentIncidentReport.rohq_status | toUpper }}</span>
          </td>
          <td class="align-middle">STGT</td>
          <td class="align-middle pr-5">
            <select class="form-control form-control-sm" v-model="editReport.stgt_status_id" v-if="toEdit">
              <option :value="null">---- SELECT STATUS ----</option>
              <option :value="3">Discussed</option>
              <option :value="2">Reviewing</option>
              <option :value="1">Pending</option>
            </select>
            <span v-else class="font-weight-bold">{{ accidentIncidentReport.stgt_status | toUpper }}</span>
          </td>
        </tr>
      </table>
      <hr>
      <table class="w-100">
        <tr>

          <td class="align-middle" style="width: 15%">
            Management Branch Company
            <sub class="lead e-text-red font-weight-bold mr-2">*</sub>
          </td>
          <td class="text-uppercase align-middle">
            <span v-if="toEdit" style="white-space: pre-line">
              {{ tmp.managementCompanyName }}
            </span>
             <span v-else style="white-space: pre-line">
            {{ accidentIncidentReport.managementCompany ? accidentIncidentReport.managementCompany : '' }}
            </span>
          </td>
          <td style="width: 15%" class="align-middle">
            Management Branch Office
            <sub class="lead e-text-red font-weight-bold mr-2">*</sub>
          </td>
          <td v-if="toEdit" style="width: 21%;" class="pr-5">
            <select name="management-branch" @change="changeTmpManagementCompanyName" v-model="editReport.management_office_id" class="form-control form-control-sm"
                    id="management-branch">
<!--              <option :value="null">-&#45;&#45; SELECT MANAGEMENT OFFICE -&#45;&#45;</option>-->
              <option :value="office.id" v-for="office in mngmntOfficeKeys ">
                {{ office.name | toUpper }}
              </option>
            </select>
          </td>
          <td v-else class="text-uppercase align-middle">
            <span>
            {{ accidentIncidentReport.managementOffice ? accidentIncidentReport.managementOffice : '' }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="pb-0" style="width: 12%;">Person In Charge
            <sub class="lead e-text-red font-weight-bold mr-2">*</sub>
            <font-awesome-icon icon="question-circle"
                               data-toggle="modal"
                               style="cursor:pointer"
                               data-target="#personInChargeDescription">
            </font-awesome-icon>
          </td>
          <td v-if="toEdit" style="width: 21%;" class="pr-5">
            <select name="" class="form-control form-control-sm text-uppercase" v-model="editReport.person_in_charge_id">
              <option :value="null">——Select Person In Charge——</option>
              <option v-for="user in userKeys" :key="user.id" :value="user.id"> {{ user.last_name }}, {{ user.first_name }}</option>
            </select>
          </td>
          <td v-else style="width: 21%;" class="text-capitalize font-weight-bold pb-0">
            <span>{{ accidentIncidentReport.personInCharge }}</span>
          </td>
          <td class="pb-0" style="width: 12%;">Responsible Person
            <sub class="lead e-text-red font-weight-bold mr-2">*</sub>
            <font-awesome-icon icon="question-circle"
                               data-toggle="modal"
                               style="cursor:pointer"
                               data-target="#responsiblePersonDescription">
            </font-awesome-icon>
          </td>
          <td v-if="toEdit" style="width: 21%;" class="pr-5">
            <select name="" class="form-control form-control-sm text-uppercase" v-model="editReport.responsible_person_id">
              <option :value="null">——Select Responsible——</option>
              <option v-for="user in userKeys" :key="user.id" :value="user.id"> {{ user.last_name }}, {{ user.first_name }}</option>
            </select>
          </td>
          <td v-else style="width: 21%;" class="text-capitalize font-weight-bold pb-0">
            <span>{{ accidentIncidentReport.responsiblePerson }}</span>
          </td>
          <td class="pb-0" style="width: 12%;">Report Author</td>
          <td v-if="toEdit" style="width: 21%;">
            <input type="text" class="form-control form-control-sm" placeholder="Author Name" v-model="editReport.author">
          </td>
          <td v-else style="width: 21%;" class="text-capitalize font-weight-bold pb-0">
            {{ accidentIncidentReport.author }}
          </td>
        </tr>
      </table>
      <hr/>
      <table class="w-100">
        <tr>
          <td style="width: 15%">Event Date <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td v-if="toEdit" class="pr-5" style="width: 21%;">
            <input type="date" :min="minimumDate" :max="currentDate" name="event-date" class="form-control form-control-sm"
                   v-model="editReport.event_date">
          </td>
          <td v-else style="width: 21%;">
            {{ accidentIncidentReport.event_date | moment('D MMMM YYYY') }}
          </td>
          <td style="width: 15%">Local Time</td>
          <td v-if="toEdit">
            <input type="time" name="local-time" class="form-control form-control-sm" v-model="editReport.event_time">
          </td>
          <td v-else>{{ accidentIncidentReport.event_time }}</td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td>Time Zone</td>
          <td v-if="toEdit">
            <select class="form-control form-control-sm" name="timezone-update" id="timezone-update" v-model="editReport.timezone_id">
              <option :value="null">&lt;None&gt;</option>
              <option v-for="timezone in timezones" :key="timezone.id" :value="timezone.id">{{ timezone.gmt }}</option>
            </select>
          </td>
          <td v-else>
            <span v-if="accidentIncidentReport.timezone">
              ({{ accidentIncidentReport.diff_from_gtm ? accidentIncidentReport.diff_from_gtm : '' }})
              {{ accidentIncidentReport.timezone ? accidentIncidentReport.timezone : '' }}
            </span>
          </td>
        </tr>
        <tr>
          <td>Place <sub class="lead e-text-red font-weight-bold">*</sub></td>
          <td class="pr-5">
            <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="At Sea / At Port" v-model="editReport.place">
            <span v-else>{{ accidentIncidentReport.place }}</span>
          </td>
          <td>Latitude/Longitude</td>
          <td>
            <div v-if="toEdit" class="input-group">
              <input type="text" class="form-control form-control-sm" placeholder="Latitude" v-model="editReport.latitude">
              <input type="text" class="form-control form-control-sm" placeholder="Longtitude" v-model="editReport.longitude">
            </div>
            <span v-else>
              {{ accidentIncidentReport.latitude }}
              {{ accidentIncidentReport.longitude ? '/' : '' }}
              {{ accidentIncidentReport.longitude }}
            </span>
          </td>
        </tr>
        <tr>
          <td>Sea Route(From)</td>
          <td class="pr-5">
            <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="Sea Route From" v-model="editReport.route_from">
            <span v-else>
              {{ accidentIncidentReport.route_from }}
            </span>
          </td>
          <td>Sea Route(To)</td>
          <td>
            <input v-if="toEdit" type="text" class="form-control form-control-sm" placeholder="Sea Route To" v-model="editReport.route_to">
            <span v-else>
              {{ accidentIncidentReport.route_to }}
            </span>
          </td>
        </tr>
      </table>
    </div>
    <nav class="navbar navbar-expand-lg mt-2" id="ModelNav">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'General'}"
                         v-if="isAllowed('accident-incident.general.menu')">
              <span>General</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Event'}"
                         v-if="isAllowed('accident-incident.event.menu')">
              <span>Event</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Loss'}"
                         v-if="isAllowed('accident-incident.loss.menu')">
              <span>Loss</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Cause'}"
                         v-if="isAllowed('accident-incident.cause.menu')">
              <span>Cause</span>
            </router-link>
          </li>
          <li v-if="isAllowed('accident-incident.attachment.menu')" class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Attachment'}"
                         v-if="isAllowed('accident-incident.attachment.menu')">
              <span>Attachment</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'CorrectiveAction'}"
                         v-if="isAllowed('accident-incident.corrective-action.menu')">
              <span>Corrective Action</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'PreventiveAction'}"
                         v-if="isAllowed('accident-incident.preventive-measures.menu')">
              <span>Preventative Measures</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'AirCompanyInstruction'}"
                         v-if="isAllowed('accident-incident.company-instruction.menu')">
              <span>Company's Instruction</span>
            </router-link>
          </li>
          <!--          :to="{ name: 'ShipParticular'}"-->
          <li class="nav-item">
            <router-link class="nav-link"
                         :to="{ name: 'ShipParticular', params: {vesselId: accidentIncidentReport.vessel_id } }"
                         v-if="isAllowed('accident-incident.ships-particular.menu')">
              <span>Ship's Particular</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
    <router-view v-if="cycle.created"/>
    <PopperModal v-if="cycle.created" popper-id="requirementsBeforeClosing">
      <template #popperHeader>Required Fields Before Close</template>
      <template #popperBody>
        <table style="width: 100%;">
          <tr>
            <th colspan="3" class="h6">Accident Incident Report</th>
          </tr>
          <tr>
            <th>
              Vessel:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.vessel ? 'check' : 'times'"
                :class="accidentIncidentReport.vessel ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.vessel ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.vessel }}
            </td>
          </tr>
          <tr>
            <th>
              Title:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.title ? 'check' : 'times'"
                :class="accidentIncidentReport.title ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.title ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.title }}
            </td>
          </tr>
          <tr>
            <th>
              Event Date:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.event_date ? 'check' : 'times'"
                :class="accidentIncidentReport.event_date ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.event_date ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.event_date | moment('D MMM YYYY') }}
            </td>
          </tr>
          <tr>
            <th>
              Event Class:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.eventClass ? 'check' : 'times'"
                :class="accidentIncidentReport.eventClass ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.eventClass ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.eventClass }}
            </td>
          </tr>

          <tr>
            <th>
              Management Branch Company:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.managementCompany ? 'check' : 'times'"
                :class="accidentIncidentReport.managementCompany ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td class="text-uppercase" :class="accidentIncidentReport.managementCompany ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.managementCompany }}
            </td>
          </tr>

          <tr>
            <th>
              Management Branch Office:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.managementOffice ? 'check' : 'times'"
                :class="accidentIncidentReport.managementOffice ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td class="text-uppercase" :class="accidentIncidentReport.managementOffice ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.managementOffice }}
            </td>
          </tr>

          <tr>
            <th>
              Person In Charge:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.personInCharge ? 'check' : 'times'"
                :class="accidentIncidentReport.personInCharge ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.personInCharge ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.personInCharge }}
            </td>
          </tr>
          <tr>
            <th>
              Responsible Person:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.responsiblePerson ? 'check' : 'times'"
                :class="accidentIncidentReport.responsiblePerson ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.responsiblePerson ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.responsiblePerson }}
            </td>
          </tr>
          <tr>
            <th>
              Place:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.place ? 'check' : 'times'"
                :class="accidentIncidentReport.place ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.place ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.place }}
            </td>
          </tr>

          <tr>
            <th colspan="3" class="h6 pt-3">General</th>
          </tr>
          <tr>
            <th>
              Charterer:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.charterer ? 'check' : 'times'"
                :class="accidentIncidentReport.charterer ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.charterer ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.charterer }}
            </td>
          </tr>

          <tr>
            <th colspan="3" class="h6 pt-3">Events</th>
          </tr>
          <tr>
            <th>
              Event Details:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.event.event_details ? 'check' : 'times'"
                :class="accidentIncidentReport.event.event_details ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.event.event_details ? 'e-bg-white' : 'e-bg-red-light-7'">
              <span v-if="accidentIncidentReport.event.event_details"
                    class="e-text-blue-gray-light-5">
                . . . See Details on "Event" Tab . . .
              </span>
            </td>
          </tr>
          <tr>
            <th>
              Event Type One:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.event.eventOne ? 'check' : 'times'"
                :class="accidentIncidentReport.event.eventOne ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td class="text-uppercase" :class="accidentIncidentReport.event.eventOne ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.event.eventOneCode }}_
              {{ accidentIncidentReport.event.eventOne }}
            </td>
          </tr>
          <tr>
            <th>
              Event Type Two:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.event.eventTwo ? 'check' : 'times'"
                :class="accidentIncidentReport.event.eventTwo ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td class="text-uppercase" :class="accidentIncidentReport.event.eventTwo ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.event.eventTwoCode }}_
              {{ accidentIncidentReport.event.eventTwo }}
            </td>
          </tr>
          <tr>
            <th>
              Event Type Three:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.event.eventThree ? 'check' : 'times'"
                :class="accidentIncidentReport.event.eventThree ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td class="text-uppercase" :class="accidentIncidentReport.event.eventThree ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.event.eventThreeCode }}_
              {{ accidentIncidentReport.event.eventThree }}
            </td>
          </tr>

          <tr>
            <th colspan="3" class="h6 pt-3">Loss</th>
          </tr>
          <tr>
            <th>
              Is Schedule Delay? :
            </th>
            <td style="width: 25px;"></td>
            <td>
              {{ accidentIncidentReport.loss.is_delay_schedule ? 'YES' : 'NO' }}
            </td>
          </tr>
          <template v-if="accidentIncidentReport.loss.is_delay_schedule">
            <tr>
              <th>
                Delay Schedule :
              </th>
              <td colspan="2">
                <div class="row no-gutters">
                  <div class="col-4" :class="accidentIncidentReport.loss.delay_sched_day ? 'e-bg-white' : 'e-bg-red-light-7'">
                  <span style="width: 25px;" class="d-inline-block bg-white">
                    <font-awesome-icon
                      :icon="( accidentIncidentReport.loss.delay_sched_day ||
                               accidentIncidentReport.loss.delay_sched_hour ||
                               accidentIncidentReport.loss.delay_sched_minute ) ? 'check' : 'times'"

                      :class="( accidentIncidentReport.loss.delay_sched_day ||
                               accidentIncidentReport.loss.delay_sched_hour ||
                               accidentIncidentReport.loss.delay_sched_minute ) ? 'e-text-green' : 'e-text-red'"/>
                  </span>
                    <span class="bg-white d-inline-block pr-1">
                      Day :
                    </span>
                    <span class="d-inline-block px-2">
                    {{ accidentIncidentReport.loss.delay_sched_day }}
                  </span>
                  </div>
                  <div class="col-4" :class="accidentIncidentReport.loss.delay_sched_hour ? 'e-bg-white' : 'e-bg-red-light-7'">
                    <span class="bg-white d-inline-block pl-3 pr-1">
                      Hour :
                    </span>
                    <span class="d-inline-block px-2">
                    {{ accidentIncidentReport.loss.delay_sched_hour }}
                  </span>
                  </div>
                  <div class="col-4" :class="accidentIncidentReport.loss.delay_sched_minute ? 'e-bg-white' : 'e-bg-red-light-7'">
                    <span class="bg-white d-inline-block pl-3 pr-1">
                      Minute :
                    </span>
                    <span class="d-inline-block px-2">
                    {{ accidentIncidentReport.loss.delay_sched_minute }}
                  </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                Delay Schedule Remarks:
              </th>
              <td style="width: 25px;">
                <font-awesome-icon
                  :icon="accidentIncidentReport.loss.delay_sched_remarks ? 'check' : 'times'"
                  :class="accidentIncidentReport.loss.delay_sched_remarks ? 'e-text-green' : 'e-text-red'"/>
              </td>
              <td :class="accidentIncidentReport.loss.delay_sched_remarks ? 'e-bg-white' : 'e-bg-red-light-7'">
              <span v-if="accidentIncidentReport.loss.delay_sched_remarks"
                    class="e-text-blue-gray-light-5">
                . . . See Remarks on "Loss" Tab . . .
              </span>
              </td>
            </tr>
          </template>

          <tr>
            <th colspan="3" class="h6 pt-3">Cause</th>
          </tr>
          <tr>
            <th>
              Immediate Cause (Substandard Act):
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.cause.immediateCause ? 'check' : 'times'"
                :class="accidentIncidentReport.cause.immediateCause ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.cause.immediateCause ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.cause.immediateCause }}
            </td>
          </tr>
          <tr>
            <th>
              Root Cause:
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.cause.rootCause ? 'check' : 'times'"
                :class="accidentIncidentReport.cause.rootCause ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.cause.rootCause ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.cause.rootCause }}
            </td>
          </tr>

          <tr>
            <th colspan="3" class="h6 pt-3">Corrective Action</th>
          </tr>
          <tr>
            <th>
              Instruct to take Corrective Action :
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.corrective.instruction ? 'check' : 'times'"
                :class="accidentIncidentReport.corrective.instruction ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.corrective.instruction ? 'e-bg-white' : 'e-bg-red-light-7'">
              <span v-if="accidentIncidentReport.corrective.instruction"
                    class="e-text-blue-gray-light-5">
                . . . See Instruct to take Corrective Action on "Corrective Action" Tab . . .
              </span>
            </td>
          </tr>
          <tr>
            <th>
              Date of Instruction :
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.corrective.instruction_date ? 'check' : 'times'"
                :class="accidentIncidentReport.corrective.instruction_date ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.corrective.instruction_date ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.corrective.instruction_date | moment('D MMM YYYY') }}
            </td>
          </tr>
          <tr>
            <th>
              Action Taken :
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.corrective.action_taken ? 'check' : 'times'"
                :class="accidentIncidentReport.corrective.action_taken ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.corrective.action_taken ? 'e-bg-white' : 'e-bg-red-light-7'">
              <span v-if="accidentIncidentReport.corrective.action_taken"
                    class="e-text-blue-gray-light-5">
                . . . See Action Taken on "Corrective Action" Tab . . .
              </span>
            </td>
          </tr>
          <tr>
            <th>
              Date of Report :
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.corrective.action_taken_date ? 'check' : 'times'"
                :class="accidentIncidentReport.corrective.action_taken_date ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.corrective.action_taken_date ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.corrective.action_taken_date | moment('D MMM YYYY') }}
            </td>
          </tr>
          <tr>
            <th>
              Reported By :
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.corrective.reported_by ? 'check' : 'times'"
                :class="accidentIncidentReport.corrective.reported_by ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.corrective.reported_by ? 'e-bg-white' : 'e-bg-red-light-7'">
              {{ accidentIncidentReport.corrective.reported_by }}
            </td>
          </tr>

          <tr>
            <th colspan="3" class="h6 pt-3">Preventive Measures</th>
          </tr>
          <tr>
            <th>
              Instruct to take Corrective Action :
            </th>
            <td style="width: 25px;">
              <font-awesome-icon
                :icon="accidentIncidentReport.preventive.details ? 'check' : 'times'"
                :class="accidentIncidentReport.preventive.details ? 'e-text-green' : 'e-text-red'"/>
            </td>
            <td :class="accidentIncidentReport.preventive.details ? 'e-bg-white' : 'e-bg-red-light-7'">
              <span v-if="accidentIncidentReport.preventive.details"
                    class="e-text-blue-gray-light-5">
                . . . See Preventive Details on "Preventative Measures" Tab . . .
              </span>
            </td>
          </tr>


        </table>
      </template>
      <template #popperFooter>
        <small>NOTE: Details/Remarks are too long to display so we just put where it could be found.</small>
      </template>
    </PopperModal>

    <EventClassHelpMenuModal modalId="eventClassDescription"></EventClassHelpMenuModal>

    <PopperModal popper-id="personInChargeDescription">
      <template #popperHeader>Person In Charge</template>
      <template #popperBody>
        <h5 class="text-center my-3 h6">
          Please select name of Superintendent in charge of vessel
        </h5>
      </template>
      <template #popperFooter>
      </template>
    </PopperModal>

    <PopperModal popper-id="responsiblePersonDescription">
      <template #popperHeader>Responsible Person</template>
      <template #popperBody>
        <h5 class="text-center my-3 h6">
          Please select name of Fleet Manager or General Manager or higher position
        </h5>
      </template>
      <template #popperFooter>
      </template>
    </PopperModal>

  </div>
</template>

<script>
import {AlertService} from '@/services/AlertService'
import {ReportService} from '@/services/ReportService'
import {ALL_USERS} from "@/services/ConstantService";
import DateMixin from "@/mixins/DateMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import PopperModal from "@/components/elements/PopperModal.vue";
import EventClassHelpMenuModal from "@/components/EventClassHelpMenuModal.vue";

const {mapActions, mapGetters} = require('vuex')
export default {
  name: 'AccidentIncidentReport',
  mixins: [DateMixin, permissionMixin],
  components: {
    PopperModal,
    EventClassHelpMenuModal
  },
  data() {
    return {
      cycle: {
        created: false,
      },
      toEdit: false,
      editStatus: false,
      currentReportStatus: {},
      currentStatus: null,
      reportStatusId: null,
      editReport: {},
      timezones: {},
      tmp: {
        managementCompanyName: null,
      },
    }
  },
  methods: {
    ...mapActions([
      'newRequestKeys',
      'addRequestKeys',
      'generateRequestKeys',
      'getReport',
      'updateAccidentIncidentReport',
      'getAccidentIncidentReport',
      'getAccidentIncidentEvent',
      'getMngmtOfficeKeys',
      'getMngmtCompanyKeys',
      'getUserKeys',
      'getVesselKeys',
      'getEventOneKeys',
      'getEventImpactKeys',
      'getTimezoneKeys',
      'getCurrencyKeys',
      'getReportStatus',
      'getCrewKeys'
    ]),
    setCurrentReportStatus() {
      Object.keys(this.reportStatusKeys).forEach(key => {
        const READY_TO_CLOSE = 4;
        // 4 == READY TO CLOSE
        if (this.reportStatusKeys[key].id === READY_TO_CLOSE && this.reportStatusId !== READY_TO_CLOSE) {
          this.reportStatusKeys[key].disabled = this.disableReadyToClose(READY_TO_CLOSE, this.accidentIncidentReport);
        }

        if (this.reportStatusKeys[key].id === this.reportStatusId) {
          this.currentReportStatus = this.reportStatusKeys[key];
        }
      });
    },

    async editValues() {
      this.toEdit = true
      if (this.toEdit) {
        this.editReport = Object.assign({}, this.accidentIncidentReport);
        this.changeTmpManagementCompanyName();
      }
    },

    async downloadAIReport(type = 'xlsx') {
      const params = {
        id: this.$route.params.id,
        // report_status_id: this.reportStatusId,
      };

      swal.fire({title: 'Please wait'});
      swal.showLoading();

      let filename = 'Accident_Incident_Report' + '_' + this.accidentIncidentReport.vessel + '_' + Date.now() + '.' + type;
      let mime_type = (type === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel');
      let file = await ReportService.downloadAIReport(params, type)
      let url = window.URL.createObjectURL(new Blob([file.data], {type: mime_type}));

      const link = document.createElement('a');
      link.setAttribute('href', url);

      if (type === 'pdf') {
        link.setAttribute('target', '_blank');
        link.download = filename;
      } else {
        link.setAttribute('download', filename);
      }

      swal.close();
      document.body.appendChild(link);
      link.click();
    },

    async saveReportStatus() {
      const currStatusName = this.reportStatusKeys.filter(stat => stat.id === this.currentStatus)[0].name;
      const changeReportStatusName = this.reportStatusKeys.filter(stat => stat.id === this.reportStatusId)[0].name;
      const verify = swal.fire({
        icon: 'question',
        title: 'CHANGE REPORT STATUS',
        html: `CHANGING <strong> ${currStatusName} </strong> STATUS TO <strong> ${changeReportStatusName} </strong> STATUS `,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'PROCEED',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545'
      }).then(result => {
        return result;
      })

      if ((await verify).isConfirmed) {
        const params = {
          accident_incident_id: this.$route.params.id,
          report_status_id: this.reportStatusId,
        }
        const response = await ReportService.updateReportStatus(params)
        if (response?.success) {
          this.$store.commit('setAccidentIncidentReportStatus', this.reportStatusId)
          AlertService.successAlert('STATUS CHANGED SUCCESSFULLY', 'UPDATE REPORT STATUS')
          this.editStatus = false;
        }

        await this.getReportStatus(this.reportStatusId);
        this.setCurrentReportStatus();
      }

    },

    editReportStatus() {
      if (this.isAllowed('accident-incident.status.editable')) {
        this.currentStatus = this.accidentIncidentReport.report_status_id ? this.accidentIncidentReport.report_status_id : 1;
        this.reportStatusId = this.accidentIncidentReport.report_status_id ? this.accidentIncidentReport.report_status_id : 1;
        this.editStatus = true;
      }
    },
    async updateReport() {

      if (await AlertService.confirmUpdateAlert()) {
        delete this.editReport.report_status_id
        let __update = await this.updateAccidentIncidentReport(this.editReport);

        if (__update) {

          AlertService.successAlert('Done Updating Report Details', 'UPDATE')
          this.toEdit = false
          this.editReport = Object.assign({}, this.accidentIncidentReport);
          if (this.$route.name == 'ShipParticular') {
            await this.$router.push(
              {
                name: 'ShipParticular',
                params: {
                  vesselId: this.accidentIncidentReport.vessel_id,
                },
              },
            ).catch(() => {
            })

            this.$router.go();
          }
        }

      }

    },

    timezoneKeysSetup() {
      this.timezones = this.timezoneKeys.sort(function (a, b) {
        const re = /^([+-]\d{1,2}):(\d{1,2}).*$/;
        const aOffset = parseFloat(a.offset.replace(re, "$1.$2"));
        const bOffset = parseFloat(b.offset.replace(re, "$1.$2"));

        return aOffset < bOffset ? -1 : aOffset > bOffset ? 1 : 0;
      }).filter((item, index, self) => {
        return self.findIndex(i => i.offset === item.offset) === index;
      }).map(item => {
        item.gmt = 'LT=GMT ' + item.gmt;
        return item;
      });
    },
    async cancelEditReport() {
      if (await AlertService.cancelAlert()) {
        this.toEdit = false
      }
    },
    disableReadyToClose(statusId, accidentIncident) {
      const READY_TO_CLOSE = 4
      if (statusId === READY_TO_CLOSE) {
        return !this.canReadyToClose(accidentIncident);
      } else {
        return false;
      }
    },
    disableClose(statusId, accidentIncident) {
      const CLOSE = 3
      if (statusId === CLOSE) {
        return !this.canClose(accidentIncident)
      }
      return false;
    },
    changeTmpManagementCompanyName() {
      this.tmp.managementCompanyName = null;
      let managementOffice = {};
      let managementCompany = {};

      if (this.editReport.management_office_id) {
        managementOffice = Object.values(this.mngmntOfficeKeys.filter(item => item.id === this.editReport.management_office_id))[0];
        if (managementOffice.hasOwnProperty('management_company_id')) {
          managementCompany = Object.values(this.mngmtCompanyKeys.filter(item => item.id === managementOffice.management_company_id))[0];
        }
      }
      this.tmp.managementCompanyName = managementCompany.hasOwnProperty('name') ? managementCompany.name : null;
    }
  },

  async created() {
    swal.fire({
      'title': 'LOADING RESOURCES',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    })
    swal.showLoading()
    this.addRequestKeys([
      'getMngmtOfficeKeys',
      'getMngmtCompanyKeys',
      'getUserKeys',
      'getVesselKeys',
      'getEventOneKeys',
      'getEventImpactKeys',
      'getTimezoneKeys',
      'getCurrencyKeys',
      // 'getReportStatusKeys',
      // 'getCrewKeys',
    ]);
    await this.generateRequestKeys();
    swal.close()

    const params = {
      reportId: this.$route.params.id
    }
    const res = await this.getAccidentIncidentReport(params.reportId)
    this.nonCmsMannings = this.accidentIncidentReport._vessel.non_cms_mannings;

    //TODO: RESPONSE ON CONFIDENTIAL REPORT REDIRECT
    if (!res.errors) {
      if (!Object.keys(this.nonCmsMannings).length) {
        swal.fire({
          'title': 'ESTABLISHING CONNECTION TO CMS',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        })
        let crewListParam = {
          onboard_from: this.accidentIncidentReport.event_date,
          onboard_to: this.accidentIncidentReport.event_date,
          vesselName: this.accidentIncidentReport.vessel,
        }

        swal.showLoading()
        const response = await this.getCrewKeys(crewListParam);
        if (!response.errors) {
          swal.close();
        } else {
          let errors = response.errors
          let error_message = '';
          error_message += '<ol style="text-align: left; font-size: 1em">';
          Object.keys(errors).forEach(key => {
            let err_msg = errors[key].join(',');
            error_message += '<li class="e-text-red"><small>* ' + err_msg + '</small></li>';
          });
          error_message += '</ol>';
          await AlertService.errorAlert(error_message, 'GET CREW ONBOARD LISTS')
        }
      }
    }

    this.reportStatusId = this.accidentIncidentReport.report_status_id;
    await this.getReportStatus(this.reportStatusId);
    this.setCurrentReportStatus();
    this.timezoneKeysSetup();
    this.cycle.created = true;
  },
  computed: {
    ...mapGetters([
      'accidentIncidentReport',
      'vesselKeys',
      'eventOneKeys',
      'eventImpactKeys',
      'timezoneKeys',
      'currencies',
      'userKeys',
      'reportStatusKeys',
      'requestKeys',
      'mngmntOfficeKeys',
      'mngmtCompanyKeys',
    ]),
  }
}
</script>

<style scoped lang="scss">
.nav > li {
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 5px;

  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;

  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}

#ModelNav {
  padding: 0;

  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}

table td {
  padding-bottom: .25rem;
}
</style>
